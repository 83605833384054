import Layout from '../components/Layout';
import SectionTitle from '../components/SectionTitle';

import React from 'react';

const NotFound = () => {
  return (
    <Layout hasMinHeight>
      <SectionTitle title="PAGINA NON TROVATA" subtitle="Questa pagina non esiste." />

      <button
        type="button"
        onClick={() => {
          window.location.replace('/');
        }}
      >
        HOME PAGE
      </button>
    </Layout>
  );
};

export default NotFound;
